<template>
  <div
    class="w-full"
    style="background: #FC9A10;height:100vh;overflow-y: scroll;"
  >
    <div>
      <img src="../../assets/images/award/award1.png" style="width:100%;" alt />
    </div>
    <div class="flex justify-center" style="transform:translateY(-40px)">
      <div class="box">
        <img src="../../assets/images/award/bg.png" width="100%" />
        <LuckyWheel
          class="luck-draw"
          ref="LuckyWheel"
          width="245px"
          height="245px"
          :default-style="defaultStyle"
          :blocks="blocks"
          :prizes="prizes"
          :buttons="buttons"
          @start="startCallBack"
          @end="endCallBack"
        />
      </div>
    </div>
    <div class="w-full px-4" style="transform:translateY(-30px)">
      <div class="w-full">
        <div
          class="w-full py-2 text-lg text-center text-white title"
          style="background: #DD171E;"
        >
          活动规则
        </div>
        <div class="p-2 text-left text-gray-600 bg-white rounded-b-lg content">
          <div>
            1、活动期间每日可免费获得一次抽奖机会，奖品为
            积分、实物、卡劵等。中奖用户登录填写个人信息后
            可领取奖品。奖品将在10日之内发放。
          </div>
          <div>
            2、活动期间每日可免费获得一次抽奖机会，奖品为
            积分、实物、卡劵等。中奖用户登录填写个人信息后
            可领取奖品。奖品将在10日之内发放。
          </div>
        </div>
      </div>
    </div>
    <div class="flex justify-center px-16 pb-6">
      <div
        class="w-full py-1 text-center bg-white border-2 rounded btn text-primary border-primary"
        @click="goRecord"
      >
        查看中奖纪录
      </div>
    </div>
    <!-- <div class="flex justify-center px-16 pb-6">
            <div
                class="w-full py-1 text-center bg-white border-2 rounded btn text-primary border-primary"
                @click="removeToken"
            >清理token</div>
        </div>-->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      prizes: [],
      buttons: [
        {
          radius: "45px",
          imgs: [
            {
              src: require("../../assets/images/award/btn.png"),
              width: "102%",
              top: "-127%",
            },
          ],
        },
      ],
      blocks: [
        { padding: "3px", background: "#a70c1b" },
        { padding: "6px", background: "#ffb633" },
      ],
      defaultStyle: {
        fontColor: "#a70c1b",
        fontSize: "10px",
      },
      prizeList: [], // 后台请求回来的奖品
      canDraw: false, // 是否可以进行抽奖的标识
    };
  },
  created() {
    this.findLottery();
    localStorage.setItem("LuckyDraw", "true"); // 保存用户通过公众号打开公众号的标识
  },
  mounted() {
    // 对返回的code进行处理
    var params = this.getUrlParam("code");
    // alert(`params:${params}${this.getLoginStatus}`)
    if (this.getLoginStatus) {
      // 如果是登录了就获取用户信息，判断token是否已经失效
      // this.$store.dispatch("aquireUserInfo")
    }
    if (!params && !this.getLoginStatus) {
      this.$store.commit("accountAuthorization", "/award");
    }
    if (params) {
      this.$store.dispatch("wechatLogin", { code: params, loginType: 4 });
    }
  },
  // beforeDestroy() {
  //     localStorage.removeItem("LOGIN_INFO")
  // },
  computed: {
    ...mapGetters(["getLoginStatus"]),
  },
  // mounted() {
  //     this.getPrizesList()
  // },
  methods: {
    removeToken() {
      localStorage.removeItem("LOGIN_INFO");
    },
    getPrizesList(list) {
      const prizes = [];
      // let data = [
      //     { name: '谢谢参与', img: require('../../assets/images/award/0.png') },
      //     { name: '红包', img: require('../../assets/images/award/1.png') },
      //     { name: '抽奖次数+3', img: require('../../assets/images/award/1.png') },
      //     { name: '礼物', img: require('../../assets/images/award/1.png') },
      //     { name: '谢谢参与', img: require('../../assets/images/award/1.png') },
      //     { name: '红包', img: require('../../assets/images/award/1.png') },
      //     { name: '抽奖次数+3', img: require('../../assets/images/award/1.png') },
      //     { name: '礼物', img: require('../../assets/images/award/1.png') }
      // ]
      list.forEach((item, index) => {
        prizes.push({
          name: item.name,
          background: index % 2 === 0 ? "#ffd099" : "#fff",
          fonts: [{ text: item.name, top: "8%" }],
          imgs: [{ src: item.img, width: "30%", top: "30%" }],
        });
      });
      this.prizes = prizes;
    },
    startCallBack() {
      if (!this.canDraw) {
        this.$EventBus.$emit("toast", {
          type: "error",
          message: "无法进行抽奖，请稍后再试",
        });
        return;
      }
      this.lottery();

      // this.$refs.LuckyWheel.play()
      // setTimeout(() => {
      //     this.$refs.LuckyWheel.stop(0)
      // }, 2000)
    },
    endCallBack(prize) {
      // alert(`恭喜你获得${prize.name}`)
      this.$EventBus.$emit("toast", {
        type: "success",
        message: `恭喜你获得${prize.name}`,
      });
    },
    goRecord() {
      if (!this.getLoginStatus) {
        this.$EventBus.$emit("toast", { type: "error", message: "您未登录" });
        return;
      }
      this.$router.push({ path: "/record" });
    },

    // 开始抽奖
    lottery() {
      this.$request({
        method: "post",
        url: this.$requestPath.lottery,
      })
        .then((res) => {
          console.log("开始抽奖", res);
          if (res.code == 0 && res.data.luckDrawId) {
            this.$refs.LuckyWheel.play();
            let index = this.prizeList.findIndex((item) => {
              return item.luckDrawId == res.data.luckDrawId;
            });
            setTimeout(() => {
              this.$refs.LuckyWheel.stop(index);
            }, 2000);
          } else {
            this.$EventBus.$emit("toast", { type: "error", message: res.msg });
          }
          // this.prizeList = []
          // if (res.code == 0) {
          //     this.canDraw = true
          //     res.data.forEach(item => {
          //         item.name = item.prizeName
          //         item.img = item.luckDrawUrl
          //     })
          //     this.prizeList.push(...res.data)
          //     this.getPrizesList(this.prizeList)
          // } else {
          //     this.canDraw = false
          //     this.$EventBus.$emit("toast", { type: 'error', message: "获取抽奖奖品失败，请稍后再试" })
          // }
        })
        .catch((error) => {
          console.log("获取底部信息error", error);
        });
    },
    // 获取抽奖奖品
    findLottery() {
      this.$request({
        method: "get",
        url: this.$requestPath.findLottery,
      })
        .then((res) => {
          // console.log("获取抽奖奖品", res)
          this.prizeList = [];
          if (res.code == 0) {
            this.canDraw = true;
            res.data.forEach((item) => {
              item.name = item.prizeName;
              item.img = item.luckDrawUrl;
            });
            this.prizeList.push(...res.data);
            this.getPrizesList(this.prizeList);
          } else {
            this.canDraw = false;
            this.$EventBus.$emit("toast", {
              type: "error",
              message: "获取抽奖奖品失败，请稍后再试",
            });
          }
          // if (res.code == 0) {
          //     this.infoData = Object.assign({}, this.infoData, res.data)
          // } else {
          //     this.$EventBus.$emit("toast", { type: 'error', message: "获取底部信息失败" })
          // }
        })
        .catch((error) => {
          console.log("获取底部信息error", error);
        });
    },
  },
};
</script>

<style scoped>
.box {
  position: relative;
  width: 310px;
  height: 310px;
}
.luck-draw {
  width: 245px;
  height: 245px;
  position: absolute;
  left: 49%;
  top: 48%;
  transform: translate(-50%, -50%);
}
</style>
